<template>
  <div>
    <div class="classAdministration">
      <span></span>
      <span>文件管理</span>
    </div>
    <div class="uploadFlex">
      <el-upload
          class="upload-demo"
          :http-request="uploadFile"
          action="/frontApi/baseFile/upload"
      >
        <el-button icon="el-icon-upload2" type="primary" size="small">点击上传</el-button>
      </el-upload>
      <div class="uploadSeacher">
        <el-input size="small" v-model="uploadSearchValue" @change="seacherFiel" placeholder="请输入文件名称"></el-input>
        <el-button type="primary" size="small" @click="seacherFiel">搜索</el-button>
      </div>
    </div>
    <div v-if="progress">
      <el-progress :percentage="percentage" ></el-progress>
    </div>
    <div v-if="uploadList.length">
      <el-table
          :data="uploadList"
          style="width: 100%"
      >
        <el-table-column prop="serial" type="index" :index="indexMethod" label="序号" width="190px"></el-table-column>
        <el-table-column prop="fileOriginalName" label="文件名">
        </el-table-column>
        <el-table-column prop="fileSize" label="大小">
          <template slot-scope="scope">
            <p>{{ (scope.row.fileSize/1024).toFixed(2)}}KB</p>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="280">
          <template slot-scope="scope">
            <div class="celerityRead">
              <el-button type="primary" size="small" plain @click="deleteFile(scope.row.id)">删除</el-button>
              <el-button type="primary" size="small" @click="selectFile(scope.row)">查看</el-button>
              <el-button size="small" v-if="routeName!=='classCenter'" type="success" plain @click="addMyselfFile(scope.row)">添加</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 30px">
        <el-pagination
            class="text_center"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="uploadPage"
            :page-size="uploadSize"
            layout="total,  prev, pager, next, jumper"
            :total="uploadTotal"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="!uploadList.length" style="width: 100%">
      <el-empty :image-size="200"></el-empty>
    </div>
    <el-dialog
        :visible.sync="myselfFileShow"
        :show-close="true">
      <div class="resourceValueFlexwindow">
        <h2>请选择资源:</h2>
        <div>
          <el-select v-model="resourceValue" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" style="margin-left: 20px" @click="addFodder">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 教学设计 -->
    <el-dialog :visible.sync="designWhindow" :show-close="true">
      <div class="resourceImgas">
        <img :src="resourceImg" alt=""/>
      </div>
    </el-dialog>
    <!--    ppt-->
    <div class="pptShowWhindow">
      <el-dialog :visible.sync="pptWhindow" :show-close="true" >
        <div class="coursePPtImgFlex">
          <div>
            <div class="pptresourceImgas" v-for="(item,index) in pptResourceImg" :key="index">
              <img :src="item?item:''" alt="" @click="changePPtImg(item)"/>
            </div>
          </div>
          <div>
            <el-image :src="coursePPtimg" :preview-src-list="pptResourceImg"  class="coursePPtBigIMg"></el-image>
          </div>
        </div>
      </el-dialog>
    </div>
    <!--    视频播放-->
    <el-dialog
        :visible.sync="videoWhindow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true">
      <div class="resourceImgas" v-if="videoWhindow">
        <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  inquireTeacheruploadList,
  addTeacherResourceAliYun,
  deleteTeacherResourceAliYun,
  teacherUpLoad,
  playVoucher,
  audioImg
} from "@/api";
import {createUploader} from "@/utils/aliyunVideoUpload";
import {filePlayType} from "@/utils/fileUtil.js";
import VueAliplayerV2 from 'vue-aliplayer-v2';
  export  default {
    components: {VueAliplayerV2,},
    data(){
      return{
        percentage:0,
        uploadList:[],
        uploadPage:1,
        uploadSize:10,
        uploadTotal:0,
        myselfFileShow:false,
        options: [{
          value: 'MicroLecture',
          label: '微课'
        }, {
          value: 'Animation',
          label: '动画'
        }, {
          value: 'Teaching',
          label: '教学视频'
        }, {
          value: 'TeacherDoc',
          label: '教学设计'
        }, {
          value: 'Ebook',
          label: '电子教材'
        },{
          value: 'Other',
          label: '拓展资料'
        },{
          value: 'Objectives',
          label: '教学目标'
        },{
          value: 'courseExercises',
          label: '作业'
        }],
        resourceValue: 'MicroLecture',
        routeName:'',
        designWhindow:false,
        pptWhindow:false,
        videoWhindow:false,
        resourceImg:'',
        pptResourceImg:[],
        coursePPtimg:'',
        progress:false,
        selectFeilItem:{},
        uploadSearchValue:'',
        videoOptions: {},
      }
    },
    methods:{
      indexMethod(index) {
        return index + (this.uploadPage * this.uploadSize) -9 ;
      },
      //获取文件列表
      getInquireTeacheruploadList(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          teacherId:userInfo.id,
          schoolId:userInfo.schoolId
        };
        if(this.uploadSearchValue) data['fileOriginalName']=this.uploadSearchValue
        inquireTeacheruploadList(data).then(res=>{
          this.uploadList=res.data.records;
          this.uploadTotal=parseInt(res.data.total)
        })
      },
      seacherFiel(){
        this.getInquireTeacheruploadList()
      },
      //上传文件
      uploadFile(res){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if(!res.file)return
        let fileType = filePlayType(res.file.name);
        if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
          this.progress=true
          let aliYundata={
            "fullFileName": res.file.name,
            "coverUrl": "www.xn---xn--2d4a-439uw60e.com",
            "fileSize": res.file.size,
            "schoolId": userInfo.schoolId
          }
          let uploaderObj  = createUploader(aliYundata,"",(res,uploadInfo)=>{
            if(res === true){
              let userInfo = JSON.parse(localStorage.getItem('userInfo'))
              let data={
                thirdPartyStorageId:uploadInfo.uploadInfo.videoId,
                fileOriginalName:uploadInfo.uploadInfo.file.name,
                fileSize:uploadInfo.uploadInfo.file.size,
                teacherId:userInfo.id,
                schoolId:userInfo.schoolId
              };
              addTeacherResourceAliYun(data).then(res=>{
                if(res.code===0){
                  this.$message({
                    message: "上传成功",
                    type: "success",
                    duration: 2000
                  });
                  this.getInquireTeacheruploadList();
                }else{
                  this.$message.error(res.msg)
                }
              })
            }
          },(uploadInfo, totalSize, progress)=>{
              this.percentage=progress*100
          })
          uploaderObj.addFile(res.file)
          uploaderObj.startUpload()
        }else{
          var forms = new FormData()
          forms.append('file',res.file)
          forms.append('bizType','teacherResource')
          forms.append('aliVideoOssData',1);
          forms.append('schoolId',userInfo.schoolId)
          teacherUpLoad(forms).then(res=>{
            if(res.code==0){
              this.$message({
                message: "上传成功",
                type: "success",
                duration: 2000
              });
              this.getInquireTeacheruploadList();
            }else{
              this.$message.error(res.msg)
            }
          })
        }
      },
      goToUserCenter() {
        this.$router.replace('/userCenterIndex')
      },
      goCourseCenter() {
        this.$router.push('prepareCourseCenter')
      },
      // 分页
      handleCurrentChange: function (uploadPage) {
        this.uploadPage = uploadPage;
        this.getInquireTeacheruploadList();
      },
    //  删除文件
      deleteFile(id){
        this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              let data = {
                id: id
              }
              deleteTeacherResourceAliYun(data).then(res => {
                if (res.code == 0) {
                  this.$message({
                    message: "删除成功",
                    type: "success",
                    duration: 2000
                  });
                  this.getInquireTeacheruploadList();
                }
              })
            })
            .catch(() => {});
      },
      //添加弹窗
      addMyselfFile(item){
        this.selectFeilItem=item
        this.myselfFileShow=true
      },
      //关闭弹窗
      addFodder(){
        this.$emit('selectFeilItem',this.selectFeilItem,this.resourceValue)
        this.myselfFileShow=false
      },
      //  点击小图切换大图
      changePPtImg(item){
        this.coursePPtimg=item
      },
    //  查看文件详情
      selectFile(item){
        let fileType = filePlayType(item.fileOriginalName);
        if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then((res)=>{
            res.data.forEach(item1=>{
              this.videoOptions.playauth =item1.playAuth
              this.videoOptions.vid =item.thirdPartyStorageId
            })
            this.videoWhindow = true;
            this.designWhindow = false;
            this.pptWhindow = false;
          })
        }
        if(fileType === 'imageTypeAry'){
          this.videoWhindow = false;
          this.designWhindow = true;
          this.pptWhindow = false;
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.resourceImg = item.url
            })
          })
        }
        if(fileType === 'pdfTypeAry'){
          this.videoWhindow = false;
          this.designWhindow = true;
          this.pptWhindow = false;
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.resourceImg = item.aliVideoOssUrl
            })
          })
        }
        if(fileType === 'officeTypeAry'){
          this.videoWhindow = false;
          this.designWhindow = false;
          this.pptWhindow = true;
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.pptResourceImg = item.aliVideoOssUrl.split(',');
              this.coursePPtimg=this.pptResourceImg[0]
            })
          })
        }
      }
    },
    mounted() {
        this.getInquireTeacheruploadList();
        this.routeName=this.$route.name
    },
  }
</script>
<style>
@import "../../../../public/css/courseDetail.css";
.resourceValueFlexwindow{
  width: 400px;
  margin: 0 auto;
  padding: 50px;
}
.resourceValueFlexwindow>div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.resourceValueFlexwindow h2{
  margin-right: 22px;
  text-align: center;
  margin-bottom: 36px;
}
.uploadFlex{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.uploadSeacher{
  display: flex;
}
.uploadSeacher button{
  margin-left:10px;
}
</style>
