import {createAliYunVideoAuth,refreshAliVideoData} from '@/api/upload'
// require("@/lib/aliyun-upload-sdk/lib/aliyun-upload-sdk-1.5.2.min")
// require("@/lib/aliyun-upload-sdk/lib/aliyun-oss-sdk-6.13.0.min")
// require("@/lib/aliyun-upload-sdk/lib/es6-promise.min");

export function createUploader (reqParam,dataType,callBackFun, uploadProgressCallback = ()=>{}) {
    let uploader = new AliyunUpload.Vod({
      userId:"122",
      timeout:  60000,
      partSize:  1048576,
      parallel:  5,
      retryCount: 3,
      retryDuration:  2,
      region: 'cn-beijing',
      // 添加文件成功
      addFileSuccess: function (uploadInfo) {

      },
      // 开始上传
      onUploadstarted: function (uploadInfo) {
        // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
        // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
        // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
        // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
        // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
        // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
        if (!uploadInfo.videoId) {
            let reqCreateParam = Object.assign({},reqParam)
            createAliYunVideoAuth(reqCreateParam).then(data => {
                if(!data.data)return
                let uploadAuth = data.data.uploadAuth
                let uploadAddress = data.data.uploadAddress
                let videoId = data.data.videoId
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
            })
        }else{
            refreshAliVideoData({videoId:uploadInfo.videoId}).then(res =>{
                if(!data.data)return
                let uploadAuth = data.data.uploadAuth
                let uploadAddress = data.data.uploadAddress
                let videoId = data.data.videoId
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
            })
        }
      },
      // 文件上传成功
      onUploadSucceed: function (uploadInfo) {
        callBackFun(true,{uploadInfo:uploadInfo});
      },
      // 文件上传失败
      onUploadFailed: function (uploadInfo, code, message) {
        callBackFun(false,null);
      },
      // 取消文件上传
      onUploadCanceled: function (uploadInfo, code, message) {

      },
      // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
      onUploadProgress: function (uploadInfo, totalSize, progress) {
        console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
        let progressPercent = Math.ceil(progress * 100);
          uploadProgressCallback && typeof uploadProgressCallback === 'function' && uploadProgressCallback(uploadInfo, totalSize, progress)
      },
      // 上传凭证超时
      onUploadTokenExpired: function (uploadInfo) {
        // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
        // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
        // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          //调用刷新阿里云点播凭证
          refreshAliVideoData({videoId:uploadInfo.videoId}).then(res =>{
          })
        uploader.resumeUploadWithAuth(uploadAuth)

      },
      // 全部文件上传结束
      onUploadEnd: function (uploadInfo) {
        // callBackFun(true,uploadInfo);
      }
    })
    return uploader
  }
