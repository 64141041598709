import request from '@/utils/request'
//创建阿里云上传凭证
export function createAliYunVideoAuth(data) {
    return request({
        url: '/aliYunFile/createUploadAuth',
        method: 'POST',
        data: data
    })
}
//刷新阿里云上传凭证
export function refreshAliVideoData(data) {
    return request({
        url: '/aliYunFile/refreshUploadAuth',
        method: 'POST',
        data: data
    })
}
