<template>
  <div>
    <div class="classAdministration">
      <span></span>
      <span>试题列表</span>
    </div>
    <div v-if="!newlyPascal">
      <el-button type="primary" size="small" @click="addQuestion">新增试题</el-button>
      <div class="measurementTable" v-if="questionList.length">
        <el-table
          :data="questionList"
          style="width:100%"
      >
        <el-table-column prop="index" type="index" :index="indexMethod" label="序号" width="100"></el-table-column>
        <el-table-column prop="exercisesTitle" label="标题" width="300">
          <template slot-scope="scope">
            <p class="questionTitleSl">{{scope.row.exercisesTitle}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="starNumber" label="难度">
        </el-table-column>
        <el-table-column prop="exercisesType" label="题型">
          <template slot-scope="scope">
            <p v-if="scope.row.exercisesType=='SC'">单选题</p>
            <p v-if="scope.row.exercisesType=='MC'">多选题</p>
            <p v-if="scope.row.exercisesType=='TF'">判断题</p>
            <p v-if="scope.row.exercisesType=='FB'">填空题</p>
            <p v-if="scope.row.exercisesType=='SAQ'">简答题</p>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="280">
          <template slot-scope="scope">
            <div class="celerityRead">
              <el-button size="small"  @click="goQuestionsDetail(scope.row)">详情</el-button>
              <el-button size="small" type="primary" @click="teacherDeleteEx(scope.row)">删除</el-button>
              <el-button size="small" v-if="routeName!=='classCenter'" type="primary" @click="addQuestionList(scope.row)">添加</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
        <div style="margin: 30px 0">
          <el-pagination
              class="text_center"
              background
              @current-change="handleQuestionChange"
              :current-page.sync="questionPage"
              :page-size="questionSize"
              layout="total,  prev, pager, next, jumper"
              :total="questionTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div v-if="!questionList.length" style="width: 100%">
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
    <div v-if="newlyPascal">
      <div class="questionTypeFlex">
        <div>
          <p v-for="(item,index) in questionType" @click="changeQuestionTab(index)" class="questionTypeFlexP"
           :class="questionTypeShow==index?'questionTypeActive':''">{{ item.name }}</p>
        </div>
        <p @click="returnQuestionList">返回</p>
      </div>
      <div class="questionPad">
        <div class="cooseZhangejie">
          <div>
            <template>
              <el-select v-model="courseValue" placeholder="请选择课程" @change="changeCourse()">
                <el-option
                    v-for="item in schoolCourseLIstArr"
                    :key="item.coursedId"
                    :label="item.coursedName"
                    :value="item.courseItemList">
                </el-option>
              </el-select>
            </template>
          </div>
          <div>
            <el-cascader
                v-model="courserZ"
                :placeholder="'请选择章节'"
                :options="courserJ"
                :props="props"
                @change="changeCourseZj()"
            >
            </el-cascader>
          </div>
        </div>
        <div>
          <p class="questionTFont">试题题目</p>
          <el-input v-if="questionTypeShow==0" v-model="qustionData.questionTitleSc"></el-input>
          <el-input v-if="questionTypeShow==1"  v-model="qustionData.questionTitleMC"></el-input>
          <el-input v-if="questionTypeShow==2"  v-model="qustionData.questionTitleJD"></el-input>
          <el-input v-if="questionTypeShow==3"  v-model="qustionData.questionTitleFB"></el-input>
          <el-input v-if="questionTypeShow==4"  v-model="qustionData.questionTitleTK"></el-input>
        </div>
        <div>
          <p class="questionTFont">题目附件</p>
          <el-upload
              class="upload-demo"
              :http-request="uploadFile"
              :on-remove="handleChange"
              action="/frontApi/baseFile/upload"
          >
            <el-button icon="el-icon-upload2" type="primary">点击上传</el-button>
          </el-upload>
          <div v-if="progress">
            <el-progress :percentage="percentage" ></el-progress>
          </div>
        </div>
        <div >
          <div v-if="questionTypeShow==0">
            <p class="questionTFont">答案选项</p>
            <div class="questionTmR" v-for="(domain, index) in dynamicValidateForm.domains">
              <p>{{getLetter(index)}}:</p>
              <el-input v-model="domain.value"></el-input>
              <el-button icon="el-icon-minus" @click.prevent="removeDomain(domain)"></el-button>
            </div>
          </div>
          <div v-if="questionTypeShow==1">
            <p class="questionTFont">答案选项</p>
            <div class="questionTmR" v-for="(domain, index) in chekBoxdateForm.domains">
              <p>{{getLetter(index)}}:</p>
              <el-input v-model="domain.value"></el-input>
              <el-button icon="el-icon-minus" @click.prevent="removeChekBoxDomain(domain)"></el-button>
            </div>
          </div>
          <div v-if="questionTypeShow==3">
            <p class="questionTFont">答案选项</p>
            <div class="questionTmR">
              <p>T:</p>
              <el-input disabled placeholder="正确"></el-input>
              <p>F:</p>
              <el-input disabled placeholder="错误"></el-input>
            </div>
          </div>
          <div v-if="questionTypeShow==4">
            <p class="questionTFont">答案选项</p>
            <div class="questionTmR" >
              <el-input size="medium" v-model="questionFbNumber" placeholder="请输入填空数量"></el-input>
            </div>
          </div>
          <div>
            <p class="addQuestions" v-if="questionTypeShow==0||questionTypeShow==1" @click="addOption()">+新增选项</p>
          </div>
          <div class="answerFlex">
            <span v-if="questionTypeShow==0||questionTypeShow==1||questionTypeShow==3">正确答案:</span>
            <div v-if="questionTypeShow==0" >
              <template >
                <el-radio-group  v-model="ScRadio" >
                  <el-radio v-for="(domain, index) in dynamicValidateForm.domains" :label="index+1" :key="index">{{getLetter(index)}}</el-radio>
                </el-radio-group >
              </template>
            </div>
            <div v-if="questionTypeShow==3">
              <el-radio v-model="answerRadio" label="T">T</el-radio>
              <el-radio v-model="answerRadio" label="F">F</el-radio>
            </div>
            <div v-if="questionTypeShow==1">
              <el-checkbox-group v-model="checkedCities" @change="selectElcheckBox">
                <el-checkbox v-for="(domain, index) in chekBoxdateForm.domains" :label="index+1" :key="index">{{getLetter(index)}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div>
            <p class="questionTFont">题目分析</p>
            <el-input type="textarea" v-if="questionTypeShow==0" v-model="questionAnalysis.scAnalysis" :rows="6"></el-input>
            <el-input type="textarea" v-if="questionTypeShow==1" v-model="questionAnalysis.mcAnalysis" :rows="6"></el-input>
            <el-input type="textarea" v-if="questionTypeShow==2" v-model="questionAnalysis.jdAnalysis" :rows="6"></el-input>
            <el-input type="textarea" v-if="questionTypeShow==3" v-model="questionAnalysis.fbAnalysis" :rows="6"></el-input>
            <el-input type="textarea" v-if="questionTypeShow==4" v-model="questionAnalysis.tkAnalysis" :rows="6"></el-input>
          </div>
          <div class="answerDifficulty">
            <span class="questionTFont">试题难度:</span>
            <el-rate v-model="difficultySCore"></el-rate>
          </div>
        </div>
      </div>
      <div>
        <p class="preserveRadio" @click="preserveQuestions">保存</p>
      </div>
    </div>
    <el-dialog
        :visible.sync="myselfQuestionShow"
        :show-close="true">
      <div class="resourceValueFlexwindow">
        <h2>请选择资源:</h2>
        <div>
          <el-select v-model="resourceValue" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" style="margin-left: 20px" @click="addFodder">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  courseList,
  operationTopic,
  teacherCreateWork,
  deleteTeacherExercises,
  addTeacherResourceAliYun,
  teacherUpLoad, pigeonholeCourse
} from "@/api";
import {filePlayType} from "@/utils/fileUtil";
import {createUploader} from "@/utils/aliyunVideoUpload";


  export default {
    data(){
      return{
        questionType: [
          {id: 1, name: '单项选择题'},
          {id: 2, name: '多项选择题'},
          {id: 3, name: '简答题'},
          {id: 4, name: '判断题'},
          {id: 5, name: '填空题'},
        ],
        newlyPascal: false,
        questionTypeShow: 0,
        qustionData:{
          questionTitleSc: '',
          questionTitleMC:"",
          questionTitleJD:"",
          questionTitleFB:"",
          questionTitleTK:""
        },
        questionAnalysis:{
          scAnalysis:"",
          mcAnalysis:"",
          jdAnalysis:"",
          fbAnalysis:"",
          tkAnalysis:""
        },
        difficultySCore: null,
        checkedCities: [],
        answerRadio:"",
        courseValue:"",
        schoolCourseLIstArr:[],
        courserZ:[],
        courserJ:[],
        props: {
          value: 'id',
          label: 'name',
        },
        questionPage:1,
        questionSize:10,
        questionTotal:0,
        questionList:[],
        dynamicValidateForm: {
          domains: [{
            value: '',
          }],
        },
        chekBoxdateForm:{
          domains: [{
            value: ''
          }],
        },
        questionJDForm: {
          domains: [{
            value: '',
          }],
        },
        ScRadio:"",
        questionFbNumber:'',
        uploadId:[],
        progress:false,
        percentage:0,
        selectQuestionItem:{},
        myselfQuestionShow:false,
        resourceValue: 'MicroLecture',
        options: [{
          value: 'MicroLecture',
          label: '微课'
        }, {
          value: 'Animation',
          label: '动画'
        }, {
          value: 'Teaching',
          label: '教学视频'
        }, {
          value: 'TeacherDoc',
          label: '教学设计'
        }, {
          value: 'Ebook',
          label: '电子教材'
        },{
          value: 'Other',
          label: '拓展资料'
        },{
          value: 'Objectives',
          label: '教学目标'
        },{
          value: 'courseExercises',
          label: '作业'
        }],
        routeName:'',
      }
    },
    methods:{
      getLetter(index){
        return String.fromCharCode(65 + index)
      },
      indexMethod(index) {
        return index + (this.questionPage * this.questionSize) -9 ;
      },
      //  去试题详情
      goQuestionsDetail(item) {
        this.$router.push('/questionsDetail')
        sessionStorage.setItem('questionDetail',JSON.stringify(item))
      },
      //  新增试题标签页
      changeQuestionTab(index) {
        this.questionTypeShow = index
      },
      //新增试题
      addQuestion(){
        this.newlyPascal=true;
        this.courseValue='';
        this.courserZ='';
        this.qustionData={}
        this.dynamicValidateForm.domains.forEach(item=>{
          item.value=""
        })
        this.chekBoxdateForm.domains.forEach(item=>{
          item.value=""
        })
        this.questionJDForm.domains.forEach(item=>{
          item.value=""
        })
        this.answerRadio='';
        this.ScRadio=''
        this.checkedCities=[]
        this.difficultySCore=null
        this.questionAnalysis={}
        this.getCourseAdministrationList();
      },
      //保存
      preserveQuestions(){
        this.newlyPascal=false;
        this.teacherCreateQuestion()
      },
    //  返回试卷列表
      returnQuestionList(){
        this.newlyPascal=false
      },
      //  获取课程列表
      getCourseAdministrationList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          page:1,
          size: 100,
          teacherId:userInfo.id,
          isFile:false
        }
        pigeonholeCourse(data).then(res => {
          this.schoolCourseLIstArr=res.data.records;
          this.schoolCourseLIstArr.forEach((item,index)=>{
            this.$set(this.schoolCourseLIstArr[index],  "coursedId",item.platformCourseInfo.id);
            this.$set(this.schoolCourseLIstArr[index],  "coursedName",item.platformCourseInfo.name);
            this.$set(this.schoolCourseLIstArr[index],  "courseItemList",item.platformCourseInfo.courseItemList);
          })
        })
      },
      //  选择课程
      changeCourse(id,name) {
      //  获取课程详情树形结构
        let courseValue = this.courseValue
        const listToTree = (listArr, pid) => {
          let tree = [];
          listArr.forEach((item, index) => {
            if (item.pid === pid) {
              let child = listToTree(courseValue, item.id)
              let data = {...item}
              child && child.length && (data['children'] = child)
              tree.push(data)
            }
          })
          return tree;
        }
        this.courserJ = listToTree(courseValue, '0');
      },
    //  获取章节列表
      changeCourseZj(){
      },
      //获取试题列表
      getQuestionList(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let query = {
          page: this.questionPage,
          size: this.questionSize,
          sourceDiff:"2",
          privateTeacherData:"1",
          teacherId:userInfo.id
        }
        operationTopic(query).then(res => {
          this.questionTotal = parseInt(res.data.total);
          this.questionList = res.data.records;
        })
      },
    //  分页
      handleQuestionChange(questionPage){
        this.questionPage=questionPage;
        this.getQuestionList()
      },
    //  删除选项
      removeDomain(item){
        var index = this.dynamicValidateForm.domains.indexOf(item)
        if (index !== -1) {
          this.dynamicValidateForm.domains.splice(index, 1)
        }
      },
    //  新增选项
      addOption() {
        if(this.questionTypeShow==0){
          this.dynamicValidateForm.domains.push({
            value: '',
          });
        }
        if(this.questionTypeShow==1){
          this.chekBoxdateForm.domains.push({
            value: '',
          });
        }
        if(this.questionTypeShow==4){
          this.questionJDForm.domains.push({
            value: '',
          });
        }
      },
    //  删除复选框
      removeChekBoxDomain(){
        var index = changeCoursethis.chekBoxdateForm.domains.indexOf(item)
        if (index !== -1) {
          this.chekBoxdateForm.domains.splice(index, 1)
        }
      },
      //上传文件
      uploadFile(res){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if(!res.file)return
        let fileType = filePlayType(res.file.name);
        if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
          this.progress=true
          let aliYundata={
            "fullFileName": res.file.name,
            "coverUrl": "www.xn---xn--2d4a-439uw60e.com",
            "fileSize": res.file.size,
            "schoolId": userInfo.schoolId
          }
          let uploaderObj  = createUploader(aliYundata,"",(res,uploadInfo)=>{
            if(res === true){
              let userInfo = JSON.parse(localStorage.getItem('userInfo'))
              let data={
                thirdPartyStorageId:uploadInfo.uploadInfo.videoId,
                fileOriginalName:uploadInfo.uploadInfo.file.name,
                fileSize:uploadInfo.uploadInfo.file.size,
                teacherId:userInfo.id,
                schoolId:userInfo.schoolId
              };
              addTeacherResourceAliYun(data).then(res=>{
                if(res.code===0){
                  this.$message({
                    message: "上传成功",
                    type: "success",
                    duration: 2000
                  });
                  let upId=res.data.thirdPartyStorageId;
                  this.uploadId.push(upId);
                }else{
                  this.$message.error(res.msg)
                }
              })
            }
          },(uploadInfo, totalSize, progress)=> {
            this.percentage = progress * 100
          })
          uploaderObj.addFile(res.file)
          uploaderObj.startUpload()
        }else{
          var forms = new FormData()
          forms.append('file',res.file)
          forms.append('bizType','teacherResource')
          forms.append('aliVideoOssData',1);
          forms.append('schoolId',userInfo.schoolId)
          teacherUpLoad(forms).then(res=>{
            if(res.code==0){
              this.$message({
                message: "上传成功",
                type: "success",
                duration: 2000
              });
              let upId=res.data.thirdPartyStorageId;
              this.uploadId.push(upId);
            }else{
              this.$message.error(res.msg)
            }
          })
        }
      },
    //  教师创建习题
      teacherCreateQuestion(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          teacherId:userInfo.id,
          privateTeacherData:"1",
          platformCourseId:this.courseValue[0].platformCourseId,
          platformCourseItemId:this.courserZ[1],
          exercisesTitle:"",
          exercisesBody:"",
          correctAnswer:"",
          analysis:"",
          starNumber:this.difficultySCore,
          answerType:"TXT"
        }
        if(this.questionTypeShow==0){
          data['exercisesType']="SC";
          data['exercisesTitle']=this.qustionData.questionTitleSc;
          data['analysis']=this.questionAnalysis.scAnalysis;
          let ds=[]
          this.dynamicValidateForm.domains.forEach(item=>{
            ds.push(item.value)
          })
          data['exercisesBody']=ds.join('\@')
          if(this.ScRadio){
            data['correctAnswer']=this.ScRadio;
          }
        }
        if(this.questionTypeShow==1){
          data['exercisesType']="MC"
          data['exercisesTitle']=this.qustionData.questionTitleMC;
          data['analysis']=this.questionAnalysis.mcAnalysis;
          let McAnswer=[]
          this.chekBoxdateForm.domains.forEach(item=>{
            McAnswer.push(item.value)
          })
          data['exercisesBody']=McAnswer.join('\@');
          if(this.checkedCities){
            data['correctAnswer']=this.checkedCities.join(',');
          }
        }
        if(this.questionTypeShow==2){
          data['exercisesType']="SAQ"
          data['exercisesTitle']=this.qustionData.questionTitleJD;
          data['analysis']=this.questionAnalysis.jdAnalysis;
        }
        if(this.questionTypeShow==3){
          data['exercisesType']="TF"
          data['exercisesTitle']=this.qustionData.questionTitleFB;
          if(this.answerRadio){
            data['correctAnswer']=this.answerRadio;
          }
          data['analysis']=this.questionAnalysis.fbAnalysis;
        }
        if(this.questionTypeShow==4){
          data['exercisesType']="FB"
          data['exercisesTitle']=this.qustionData.questionTitleTK;
          data['exercisesBody']=this.questionFbNumber
          data['analysis']=this.questionAnalysis.tkAnalysis;
        }
        if(this.uploadId){
          data['exercisesAccessoryUrl']=this.uploadId.join(',')
        }
        teacherCreateWork(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.getQuestionList()
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      selectElcheckBox(){
      },
    //  教师删除习题
      teacherDeleteEx(item){
        let idList=[item.id];
        this.$confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteTeacherExercises(idList).then(res=>{
            if(res.code=="0"){
              this.getQuestionList();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }else{
              this.$message.error('失败，请重试');
            }
          })

        }).catch(() => {

        });
      },
    //
      handleChange(file,fileList){
      },
    //  添加试题
      addQuestionList(item){
        this.selectQuestionItem=item
        this.myselfQuestionShow=true
      },
      //关闭弹窗
      addFodder(){
        this.$emit('selectQuestionItem',this.selectQuestionItem,this.resourceValue)
        this.myselfQuestionShow=false
      },
    },
    mounted() {

      this.getQuestionList();
      this.routeName=this.$route.name
    }
  }
</script>
